import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import styled from "styled-components";

import { MODULE_CONFIG, MODULE_PERMISSION, Localization } from "../../constant";

const Navigator = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const user: any = JSON.parse(localStorage.getItem("tvsd.los.user"));
  const userRole = user?.role_name;

  const allowedModules = MODULE_PERMISSION.find(
    (d) => d.roleName === userRole
  )?.modules;

  const displayedModules = allowedModules
    ? MODULE_CONFIG.filter((e) => allowedModules.includes(e.moduleName))
    : [];

  if (displayedModules.length > 0) {
    return (
      <>
        <NavContainer>
          <NavigatorButton
            id="notification-button"
            aria-controls={open ? "notification-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            {Localization.isUsingWheeltekConfig || Localization.isUsingNewNemarConfig ? (
              <img
                src={`${process.env.PUBLIC_URL}/images/menu.svg`}
                alt="avatar"
                style={{ width: "32px", height: "32px" }}
              />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/images/icon-menu.svg`}
                alt="avatar"
                style={{ width: "40px", height: "40px" }}
              />
            )}
          </NavigatorButton>
          <Menu
            className="menu_item"
            id="notification-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            MenuListProps={{
              style: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                inset: "0px auto auto 0px",
                padding: "24px",
                width: "100%",
                zIndex: "9999",
                borderRadius: "0px",
                boxShadow: "0 0 5px #333",
              },
            }}
          >
            {displayedModules.map((d, idx) => {
              return (
                <MenuContainer
                  key={idx.toString()}
                  onClick={() => {
                    handleClose;
                    window.location.replace(
                      `${process.env.REACT_APP_REDIRECT_URL}?application=${d.moduleName}`
                    );
                  }}
                >
                  <img
                    src={d.image}
                    alt="avatar"
                    style={{ width: "56px", height: "56px " }}
                  />
                  <MenuTitle>{d.label}</MenuTitle>
                </MenuContainer>
              );
            })}
          </Menu>
        </NavContainer>
      </>
    );
  } else {
    return null;
  }
};

const NavContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MenuContainer = styled(MenuItem)`
  height: 140px;
  border-radius: 8px !important;
  margin: 0px 10px !important;
  padding: 24px !important;
  border: 1px solid #cbd5e0 !important;
  cursor: pointer;
  width: 110px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.1s linear;
  &:hover {
    background-color: #f2f3ff !important; /* Light gray on hover */
  }
`;

const NavigatorButton = styled(Button)`
  padding: 4px 8px !important;
  height: 55px;
  border-radius: 0 !important;
  min-width: 0 !important;
  &:hover {
    background-color: #ffffff4d !important; /* Light gray on hover */
  }
`;

const MenuTitle = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #6956ba;
  text-wrap: pretty;
  text-align: center;
  margin-top: 10%;
  white-space: pre-line;
  margin-bottom: 0;
`;

export default Navigator;
