import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { Container, Button } from 'reactstrap';
import styles from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useScreenSize from '../../utils/screenSize';
import ArrowLeft from '../icons/arrowLeft';
import ArrowRight from '../icons/arrowRight';
import ModalPortal from '../elements/ModalPortal';
import NotificationBadge from 'containers/Notification/notification-badge';
import { Localization } from 'constant';
import ActivitiLog from 'constant/svgIcons/ActivitiLog';
import { IconButton, Typography, Input } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import moduleStyles from './mobileHeader.module.scss';

const MobileHeader = ({ onClickMenu }: any) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const { auth } = useSelector((state: any) => state);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  return (
    <Div>
      <Container fluid>
        <MainContainer>
          <span className={moduleStyles.text}>Supporting Documents</span>
        </MainContainer>
      </Container>
    </Div>
  );
};

const InputStyled = styles.input`
  border: none;
  &: focus {
    border: none;
    outline: none;
  }
`;
const MainContainer = styles.div`
    display:flex;
    align-items: center;
    justify-content: flex-start;
    margin:auto;
    height: 55px;
`;
const Div = styles.div`
  background: ${Localization.navbarBackgroundColor};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 55px;
`;

const ButtonMenu = styles(Button)`
  &:after {
    clear: both;
    display: block;
    content: "";
    background: url(${process.env.PUBLIC_URL}/images/hamburger.svg);
    background-repeat: no-repeat;
    width: 26px;
    height: 19px;
  }
  background-color: transparent;
  z-index: 1;
  border: none;
  margin-right: 20px;
  padding: 8px;
  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: none !important;
    background: rgba(255, 255, 255, 0.3) !important;
    border: none;
    box-shadow: none !important;
    outline: 0;
  }
`;

export default MobileHeader;
