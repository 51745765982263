
import { all, fork } from 'redux-saga/effects';

import authSaga from './auth/saga';
import crudSaga from './crud/saga';

export default function* rootSaga() {
    yield all([
      fork(authSaga),
      fork(crudSaga),
    ]);
  }
  