/*
 * App Global Actions
 */

import { BaseAction } from '../types';
import {
  USER_LOGIN,
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_CHECK,
  USER_LOAD,
  USER_LOAD_FAILURE,
  USER_LOAD_SUCCESS,
  USER_LOAD_CANCELLED,
  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  CUSTOMER_USER_LOGIN,
  USER_RESET_PASSWORD,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_ERROR,
  USER_VERIFICATION,
  USER_VERIFICATION_SUCCESS,
  USER_VERIFICATION_ERROR,
} from './types';

export const customerLogin = (payload: object, redirect: string): BaseAction => ({
  type: CUSTOMER_USER_LOGIN,
  payload,
  meta: {
    redirect,
    success: USER_LOGIN_SUCCESS,
    failure: USER_LOGIN_ERROR,
  },
});

export const userLogin = (payload: object, redirect: string): BaseAction => ({
  type: USER_LOGIN,
  payload,
  meta: {
    redirect,
    success: USER_LOGIN_SUCCESS,
    failure: USER_LOGIN_ERROR,
  },
});

export const userCheck = (payload: {}, redirect: string): BaseAction => ({
  type: USER_CHECK,
  payload,
  meta: {
    redirect,
  },
});

export const userLoad = (): BaseAction => ({
  type: USER_LOAD,
  meta: { success: USER_LOAD_SUCCESS, failure: USER_LOAD_FAILURE, cancel: USER_LOAD_CANCELLED },
});

export const userLogout = (): BaseAction => {
  return {
    type: USER_LOGOUT,
    meta: { success: USER_LOGOUT_SUCCESS, failure: USER_LOGOUT_FAILURE },
  };
};

export const userResetPassword = (payload: object, redirect: string): BaseAction => ({
  type: USER_RESET_PASSWORD,
  payload,
  meta: {
    redirect,
    success: USER_RESET_PASSWORD_SUCCESS,
    failure: USER_RESET_PASSWORD_ERROR,
  },
});

export const userVerification = (payload: object, redirect: string): BaseAction => ({
  type: USER_VERIFICATION,
  payload,
  meta: {
    redirect,
    success: USER_VERIFICATION_SUCCESS,
    failure: USER_VERIFICATION_ERROR,
  },
});
