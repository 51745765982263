import "./App.css";
import { AppContext } from "../helpers";
import React, { FC, useCallback, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import AsyncComponent from "../components/layouts/Async";
import { ToastContainer } from "react-toastify";
import { userCheck, userLogin } from "../redux/auth/actions";
import getRoutes from "./routes";
import NoAuthTemplate from "../themes/NoAuthTemplate";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();

  const routeListRenderer = useCallback(() => {
    const availabelRoutes = getRoutes();
    return availabelRoutes.map(
      ({ noAuth, path, pageComponent, layout, isExact = false }, index) => {
        const template = layout || NoAuthTemplate;
        return (
          /* @ts-ignore */
          <Route
            key={`${pageComponent}-${index}-root`}
            path={path}
            exact={isExact}
            render={() => routeRenderer(template, pageComponent, noAuth)}
          />
        );
      }
    );
  }, []);

  useEffect(() => {
    if (window.location.href.includes("localhost")) {
      dispatch(userLogin({ localhost: true }, ""));
    }
  }, []);

  const routeRenderer = (
			Layout: FC<{ children: React.ReactNode }>,
			pageComponent: string,
			noAuth = false,
		) => {
			if (!noAuth) {
				dispatch(userCheck({}, ''));
			}

			return (
				<Layout>
					<AsyncComponent page={pageComponent} />
				</Layout>
			);
		};

  return (
    <AppContext.Provider value={{}}>
      <div className="App">
        {/* @ts-ignore */}
        <Switch>{routeListRenderer()}</Switch>
      </div>
      <ToastContainer
        style={{
          zIndex: "99999",
          top: "20px",
        }}
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </AppContext.Provider>
  );
}

export default withRouter(App);
