import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Localization } from 'constant/config';
import { Button, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { userLogout } from 'redux/auth/actions';
import { clearActionStatus, createRecord } from 'redux/crud/action';
import { State } from 'redux/types';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const link = Localization.privacyStatementUrl;

const Privacy = ({ version, setShowPrivacy }: any) => {
  const { t } = useTranslation();
  const { auth } = useSelector((state: State) => state);
  const [content, setContent] = useState('');
  const [agree, setAgree] = useState(false);
  const dispatch = useDispatch();
  const getPrivacyFile = () => {
    fetch(link)
      .then((r) => r.text())
      .then((text) => {
        setContent(text);
      });
  };
  const history = useHistory();
  useEffect(() => {
    getPrivacyFile();
  }, []);

  const render = () => {
    return content.split('\n').map((item, key) => (
      <span key={key}>
        {item}
        <br />
      </span>
    ));
  };

  const handleSubmit = () => {
    if (agree) {
      dispatch(
        createRecord('userPrivacy', 'user-legal', {
          version,
          is_accepted: true,
          type: 'privacy',
        }),
      );
    } else {
      dispatch(userLogout());
      dispatch(clearActionStatus('userPrivacy'));
    }
    setShowPrivacy(false);
  };

  return (
    <>
      <ModalHeader className="justify-content-center">
        <div
          style={{
            fontWeight: 500,
            fontSize: '20px',
            color: '#000',
          }}
        >
          {t('Privacy Notice')}
        </div>
      </ModalHeader>
      <ModalBody className="p-0">
        <div
          style={{
            maxHeight: '500px',
            overflowY: 'scroll',
            padding: '30px',
          }}
        >
          <div 
            style={{
              fontWeight: '700',
              paddingBottom: 20,
            }}
            className="text-danger"
          >
            {t('Please scroll down to accept the agreement')}
          </div>
          {render()}
          <div
            style={{
              padding: '10px 0px 20px',
            }}
          >
            <Form>
              <FormGroup check className="mt-3">
                <Input
                  type="checkbox"
                  name="check"
                  id="privacyCheck"
                  defaultChecked={false}
                  onChange={() => setAgree(!agree)}
                />
                <Label for="privacyCheck" check>
                  {t('I agree to the above Privacy Statement')}
                </Label>
              </FormGroup>
            </Form>
            {/* {!agree && <div className="text-danger">{t('If you reject the Privacy Statement, you will be logged out.')}</div>} */}
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="text-center justify-content-center">
        <LegalButton onClick={() => handleSubmit()} style={{ borderRadius: 8, color: '#ffffff' }} disabled={!agree}>
          {t('Next')}
        </LegalButton>
      </ModalFooter>
    </>
  );
};

export default Privacy;

export const LegalButton = styled(Button)`
  font-weight: 500;
  font-size: 20px;
  background: #6956BA;
  padding: 10px 24px;
`;
