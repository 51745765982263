/* eslint-disable no-param-reassign */
import produce, { Draft } from 'immer';

import authProvider from '../../utils/authProvider';
import { BaseAction } from '../types';
import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  AuthState,
  USER_LOAD_SUCCESS,
  USER_LOAD_FAILURE,
  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  CUSTOMER_USER_LOGIN,
  USER_RESET_PASSWORD,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_ERROR,
  USER_VERIFICATION,
  USER_VERIFICATION_SUCCESS,
  USER_VERIFICATION_ERROR,
} from './types';

// load token object from localStorage if exists
const token = authProvider.getToken();
const tokenData = authProvider.getTokenData();
const settingsData = authProvider.getSettingsData();

export const initialState = {
  token,
  user: { ...tokenData, fromCache: true } || false,
  settings: settingsData || false,
  pending: false,
  error: false,
  message: '',
  response_code: '',
};

const myReducer = (state: AuthState = initialState, action: BaseAction) =>
  produce(state, (draft: Draft<AuthState>) => {
    const { type, payload, error, responseCode } = action;
    switch (type) {
      case USER_VERIFICATION:
      case USER_RESET_PASSWORD:
      case CUSTOMER_USER_LOGIN:
      case USER_LOGIN:
        draft.pending = true;
        draft.error = false;
        break;
      case USER_LOGIN_SUCCESS:
        draft.pending = false;
        draft.token = payload?.user?.acc_token;
        draft.error = false;
        draft.user = payload?.user;
        draft.settings = payload?.settings;
        draft.response_code = payload?.response_code;
        draft.message = payload?.message;
        break;
      case USER_LOGIN_ERROR:
        draft.pending = false;
        draft.error = error;
        draft.response_code = responseCode;
        break;
      case USER_LOAD_SUCCESS:
        draft.user = payload;
        break;
      case USER_LOAD_FAILURE:
        draft.user = false;
        break;
      case USER_LOGOUT:
        draft.pending = true;
        break;
      case USER_LOGOUT_SUCCESS:
        draft.pending = false;
        draft.user = false;
        draft.token = false;
        break;
      case USER_LOGOUT_FAILURE:
        draft.pending = false;
        draft.user = false;
        draft.token = false;
        break;
      case USER_RESET_PASSWORD_SUCCESS:
        draft.pending = false;
        draft.user = false;
        draft.token = false;
        break;
      case USER_RESET_PASSWORD_ERROR:
        draft.pending = false;
        draft.error = error;
        break;
      case USER_VERIFICATION_SUCCESS:
        draft.pending = false;
        draft.user = false;
        draft.token = payload?.token;
        break;
      case USER_VERIFICATION_ERROR:
        draft.pending = false;
        draft.error = error;
        break;
      default:
    }
  });

export default myReducer;
