import React, { FC } from "react";

type NoAuthTemplateProps = {
  children?: React.ReactNode;
};

const NoAuthTemplate: FC<NoAuthTemplateProps> = ({ children }) => (
  <div className="main">{children}</div>
);

export default NoAuthTemplate;
