import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { History } from "history";
import crudReducer from "./crud/reducer";
import authReducer from 'redux/auth/reducer';

const rootReducer = (history: History<any>, injectedReducers = {}) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    crudState: crudReducer,
    ...injectedReducers,
  });
export default rootReducer;
