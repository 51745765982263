/* eslint-disable @typescript-eslint/no-redeclare */
/**
 * Authentication saga
 */

import { push } from "connected-react-router";
import { channel } from "redux-saga";
import {
  fork,
  flush,
  call,
  put,
  takeEvery,
  Effect,
  take,
  all,
  select,
} from "redux-saga/effects";
import authProvider from "../../utils/authProvider";
import {
  deletecall,
  get,
  getSingleById,
  post,
  update,
} from "../../utils/apiProvider";
import {
  CREATE_RECORD,
  CrudAction,
  DELETE_RECORD,
  FT_COLLECTION,
  FT_RECORD,
  UPDATE_RECORD,
} from "./types";
import {
  fetchCollection as fetchCollectionAction,
  fetchRecord as fetchRecordAction,
} from "./action";

function* dashboardChannel() {
  const chan = yield call(channel);
  yield fork(handleDashboardChannel, chan);
  try {
    while (true) {
      const { payload } = yield take("DASHBOARD");
      if (payload.type === "cancel") {
        yield flush(chan);
      } else {
        yield put(chan, payload);
      }
    }
  } catch (err) {
    // console.error(err);
  }
}

function* handleDashboardChannel(chan: any) {
  while (true) {
    const payload = yield take(chan);
    const { type, model, params, path } = payload;
    if (type === "fetchCollection")
      yield call(fetchCollection, fetchCollectionAction(model, path, params));
    else if (type === "fetchRecord")
      yield call(
        fetchRecord,
        fetchRecordAction(model, path, payload.id, params)
      );
  }
}

function* fetchCollection(action: CrudAction): Generator<Effect, void, any> {
  const state = yield select();
  const { path, params } = action.payload;
  const { success, failure } = action.meta;
  const meta = {
    ...action.meta,
    fetchTime: Date.now(),
  };
  try {
    const resp = yield call(get, path, params);
    yield put({
      meta,
      type: success,
      payload: {
        data: resp?.data?.collections || resp?.items || resp?.accounts,
        ...resp,
      },
    });
  } catch (error: any) {
    if (error.status === 401) {
      authProvider.removeToken();
      authProvider.logout();
    }
    yield put({ meta, type: failure, error: error.message });
  }
}

function* fetchRecord(action: CrudAction): Generator<Effect, void, any> {
  const state = yield select();
  const { path, params, id } = action.payload;
  const { success, failure } = action.meta;
  const meta = {
    ...action.meta,
    fetchTime: Date.now(),
  };
  try {
    const resp = yield call(getSingleById, path, id, params);
    yield put({
      meta,
      type: success,
      payload: {
        data:
          resp.collections ||
          resp.collection ||
          resp.data ||
          resp.account ||
          resp,
      },
    });
  } catch (error: any) {
    if (error.status === 401) {
      authProvider.removeToken();
      authProvider.logout();
    }
    yield put({ meta, type: failure, error: error.message });
  }
}

function* createRecord(action: CrudAction): Generator<Effect, void, any> {
  const state = yield select();
  const { path, params } = action.payload;
  const { success, failure } = action.meta;
  const meta = {
    ...action.meta,
    fetchTime: Date.now(),
  };
  try {
    const resp = yield call(post, path, params);
    yield put({ meta, type: success, payload: resp });
  } catch (error: any) {
    if (error.status === 401) {
      authProvider.removeToken();
      authProvider.logout();
    }
    yield put({ meta, type: failure, error: error.message });
  }
}

function* updateRecord(action: CrudAction): Generator<Effect, void, any> {
  const state = yield select();
  const { path, params } = action.payload;
  const { success, failure } = action.meta;
  const meta = {
    ...action.meta,
    fetchTime: Date.now(),
  };
  try {
    const resp = yield call(update, path, params);
    yield put({ meta, type: success, payload: resp });
  } catch (error: any) {
    if (error.status === 401) {
      authProvider.removeToken();
      authProvider.logout();
    }
    yield put({ meta, type: failure, error: error.message });
  }
}

function* deleteRecord(action: CrudAction): Generator<Effect, void, any> {
  const state = yield select();
  const { path, params } = action.payload;
  const { success, failure } = action.meta;
  const meta = {
    ...action.meta,
    fetchTime: Date.now(),
  };
  try {
    const resp = yield call(deletecall, path, params);
    yield put({ meta, type: success, payload: resp });
  } catch (error: any) {
    if (error.status === 401) {
      authProvider.removeToken();
      authProvider.logout();
    }
    yield put({ meta, type: failure, error: error.message });
  }
}

function* crudSaga() {
  yield takeEvery(FT_COLLECTION, fetchCollection);
  yield takeEvery(FT_RECORD, fetchRecord);
  yield takeEvery(CREATE_RECORD, createRecord);
  yield takeEvery(UPDATE_RECORD, updateRecord);
  yield takeEvery(DELETE_RECORD, deleteRecord);
}

export default function* root() {
  yield all([crudSaga(), dashboardChannel()]);
}
