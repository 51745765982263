import useScreenSize from 'utils/screenSize';
import Sidebar from 'components/layouts/Sidebar';
import ZendeskChatWidget from 'components/Zendesk/ChatWidget';
import Header from 'components/layouts/Header';
import React, { FC, useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import './styles.scss';

interface DashboardTemplateProps {
	children: React.ReactNode;
}

const DashboardTemplate: FC<DashboardTemplateProps> = ({ children }) => {
	const size = useScreenSize();
	const [showSidebar, setShowSidebar] = useState(false);
	const [triggerChat, setTriggerChat] = useState(false);
	const [sidebarChatWidgetLoading, setSidebarChatWidgetLoading] = useState(true);

	useEffect(() => {
		// Show sidebar on larger screens, hide on smaller ones
		setShowSidebar(size.screen === 'l' || size.screen === 'xl');
	}, [size]);

	return (
		<div className="dashboard-main">
			<div className="header-container">
			<Header
				onClickMenu={() => {
					window.scrollTo(0, 0);
					setShowSidebar(!showSidebar);
				}}
			/>
            </div>
			<div className="dashboard-layout">
				{showSidebar && (
					<div className="sidebar">
						<Sidebar
							size={size}
							setShowSidebar={setShowSidebar}
							supportChatLoading={sidebarChatWidgetLoading}
							onSupportChatClick={() => setTriggerChat(!triggerChat)}
						/>
					</div>
				)}
				<div className="content-area">
					<Container fluid>
						{children}
					</Container>
				</div>
			</div>
			<ZendeskChatWidget
				hideOnLoad={true}
				showChat={triggerChat}
				onFinishScriptLoad={() => setSidebarChatWidgetLoading(false)}
				onCloseChatWidget={() => setTriggerChat(false)}
			/>
		</div>
	);
};

export default DashboardTemplate;
