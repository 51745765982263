import * as React from "react";

const ArrowRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path fill="#fff" d="m10 17.46 5-5-5-5v10Z" />
  </svg>
);
export default ArrowRight;
