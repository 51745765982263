import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import {
	Container,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Button,
	Modal,
} from 'reactstrap';
import styles from 'styled-components';
import { Link } from 'react-router-dom';
import useScreenSize from '../../utils/screenSize';
import ArrowLeft from '../icons/arrowLeft';
import ArrowRight from '../icons/arrowRight';
import ModalPortal from '../elements/ModalPortal';
import NotificationBadge from 'containers/Notification/notification-badge';
import { Localization } from 'constant';
import ActivitiLog from 'constant/svgIcons/ActivitiLog';
import { IconButton, Typography, Input } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TvsLogout, TvsDownload } from 'components/icons';
import { userLogout } from 'redux/auth/actions';
import { clearActionStatus, fetchCollection } from 'redux/crud/action';
import authProvider from 'utils/authProvider';
import Eula from 'components/tvs-components/eula';
import Privacy from 'components/tvs-components/privacy';
import Navigator from 'containers/Navigator';

const Header = ({ onClickMenu }: any) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dispatch = useDispatch();
	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const { auth, crudState } = useSelector((state: any) => state);
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [eulaVersion, setEulaVersion] = useState<any>(null);
	const [privacyVersion, setPrivacyVersion] = useState<any>(null);
	const [isShowEula, setShowEula] = useState(false);
	const [isShowPrivacy, setShowPrivacy] = useState(false);

	const getEulaVersion = async () => {
		const request: any = await fetch(Localization.eulaVersionUrl);
		const response = await request.text();
		setEulaVersion(JSON.parse(response).version);
	};

	const getPrivacyVersion = async () => {
		const request: any = await fetch(Localization.privacyStatementVersion);
		const response = await request.text();
		setPrivacyVersion(JSON.parse(response).version);
	};

	useEffect(() => {
		dispatch(
			fetchCollection('userEula', 'user-legal', {
				filter: { type: 'eula' },
			}),
		);
		dispatch(
			fetchCollection('userPrivacy', 'user-legal', {
				filter: { type: 'privacy' },
			}),
		);
		getEulaVersion();
		getPrivacyVersion();
	}, [auth]);

	useEffect(() => {
		if (
			crudState.userEula?.collections &&
			!crudState.userEula?.actionStatus?.create &&
			eulaVersion
		) {
			const userEula: any = crudState.userEula?.collections?.otherInfo;
			if (userEula) {
				const versionTc = userEula?.userlegal;
				if (
					!versionTc ||
					!versionTc.is_accepted ||
					versionTc.version !== eulaVersion
				) {
					if (!Localization.isUsingMotoraceConfig) {
						setShowEula(true);
					}
				}
			}
		}
	}, [crudState.userEula, eulaVersion]);

	useEffect(() => {
		if (
			crudState.userPrivacy?.collections &&
			!crudState.userPrivacy?.actionStatus?.create &&
			privacyVersion
		) {
			const userPrivacy: any = crudState.userPrivacy?.collections?.otherInfo;
			if (userPrivacy) {
				const versionTc = userPrivacy?.userlegal;
				if (
					!versionTc ||
					!versionTc.is_accepted ||
					versionTc.version !== privacyVersion
				) {
					if (!Localization.isUsingMotoraceConfig) {
						setShowPrivacy(true);
					}
				}
			}
		}
	}, [crudState.userPrivacy, privacyVersion]);

	const open = Boolean(anchorEl);

	const route = useRouteMatch();
	const screen = useScreenSize();

	const handleClick = (event) => {
		if (anchorEl) {
			setAnchorEl(null);
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleManual = () => {
		setShowModal(true);
	};

	const handleLogout = () => {
		authProvider.logout();
		dispatch(clearActionStatus('userTc'));
		dispatch(userLogout());
	};

	const [pageNumber, setPageNumber] = useState<number>(1);
	const [maxPages, setMaxPages] = useState<number>(0);
	const getMax = (e: number) => {
		setTimeout(() => {
			setMaxPages(e);
		}, 0);
	};

	return (
		<>
			<ModalPortal
				visible={showModal}
				setVisible={setShowModal}
				closeOnClickOutside={true}>
				<div style={{ justifyContent: 'center', alignSelf: 'center', flex: 1 }}>
					{maxPages ? (
						<div
							style={{
								display: 'grid',
								gridTemplateColumns: 'repeat(20, 1fr)',
								paddingLeft: 24,
								paddingRight: 24,
								paddingTop: 16,
								paddingBottom: 16,
								backgroundColor: '#F2F3FF',
								borderTopLeftRadius: 10,
								borderTopRightRadius: 10,
							}}>
							<div>
								<InputStyled
									style={{
										gridColumn: 1,
										borderWidth: 1,
										borderRadius: 10,
										borderColor: 'gray',
										borderStyle: 'solid',
										backgroundColor: 'white',
										padding: 5,
										width: 35,
									}}
									placeholder={`${pageNumber}`}
									value={pageNumber}
									onChange={(e) => {
										e.preventDefault();
										if (+e.target.value <= maxPages && +e.target.value >= 0)
											setPageNumber(+e.target.value);
									}}
								/>
							</div>
							<div
								style={{
									gridColumn: 2,
									fontSize: 14,
									whiteSpace: 'nowrap',
									alignSelf: 'center',
									fontWeight: '700',
									marginLeft: 0,
									marginRight: 10,
								}}>
								/ {maxPages}
							</div>
							<div
								role='button'
								onClick={() => setPageNumber((prev) => (prev > 1 ? prev - 1 : prev))}
								onKeyDown={() => setPageNumber((prev) => prev - 1)}
								tabIndex={0}
								style={{
									gridColumn: 3,
									borderRadius: 10,
									backgroundColor: '#6956BA',
									alignItems: 'center',
									alignContent: 'center',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									padding: 6,
									alignSelf: 'center',
									marginRight: 10,
								}}>
								<ArrowLeft style={{ alignSelf: 'center' }} />
								<div
									style={{
										color: 'white',
										alignSelf: 'center',
										textAlign: 'center',
										marginRight: 5,
										fontWeight: '700',
									}}>
									Prev
								</div>
							</div>

							<div
								role='button'
								onClick={() =>
									setPageNumber((prev) => (prev < maxPages ? prev + 1 : prev))
								}
								onKeyDown={() => setPageNumber((prev) => prev + 1)}
								tabIndex={0}
								style={{
									gridColumn: 4,
									borderRadius: 10,
									backgroundColor: '#6956BA',
									alignItems: 'center',
									alignContent: 'center',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									padding: 6,
									alignSelf: 'center',
								}}>
								<div
									style={{
										color: 'white',
										alignSelf: 'center',
										textAlign: 'center',
										fontWeight: '700',
										marginLeft: 5,
									}}>
									Next
								</div>
								<ArrowRight style={{ alignSelf: 'center' }} />
							</div>
						</div>
					) : null}
				</div>
			</ModalPortal>
			<Div>
				<Container fluid>
					<MainContainer>
						<NotificationBadge />
						{/* <img
							src={`${process.env.PUBLIC_URL}/images/icon-task.svg`}
							alt='icon-task'
							style={{ width: '40px', height: '40px', cursor: 'pointer' }}
						/> */}
						{/* <img
							src={`${process.env.PUBLIC_URL}/images/icon-history.svg`}
							alt='icon-history'
							style={{ width: '40px', height: '40px', cursor: 'pointer' }}
						/> */}
						<Navigator />
						{/* <span
              className="d-flex align-items-center "
              style={{ verticalAlign: "middle", marginLeft: "1%" }}
            >
              <Link to="/activity-log">
                <ActivitiLog />
              </Link>
            </span> */}

						<DropdownStyle isOpen={dropdownOpen} toggle={toggle}>
							<DropdownToggleType>
								<div className='d-flex align-items-center'>
									<Circle>{auth?.user?.first_name?.charAt(0)}</Circle>
									<span style={{ fontSize: '18px' }}>{auth?.user?.email}</span>
								</div>
							</DropdownToggleType>
							<DropdownMenuStyle right container='body'>
								{/* <DropdownItem onClick={handleManual}>
                          <TvsDownload size={28} />
                          {'User Manual'}
                        </DropdownItem> */}

								<DropdownItems onClick={handleLogout}>
									<TvsLogout size={28} />
									{'Logout'}
								</DropdownItems>
							</DropdownMenuStyle>
						</DropdownStyle>
					</MainContainer>
				</Container>
				<ModalStyle isOpen={auth?.user?.id && isShowEula} size='lg' centered>
					<Eula version={eulaVersion} setShowEula={setShowEula} />
				</ModalStyle>
				<ModalStyle isOpen={auth?.user?.id && isShowPrivacy} size='lg' centered>
					<Privacy version={privacyVersion} setShowPrivacy={setShowPrivacy} />
				</ModalStyle>
			</Div>
		</>
	);
};

const InputStyled = styles.input`
  border: none;
  &: focus {
    border: none;
    outline: none;
  }
`;
const MainContainer = styles.div`
display:flex;
flex-direction:row;
justify-content:flex-end;
align-items:center;
margin:auto;
padding:0.38rem 1rem;
gap: 5px;
`;

const Div = styles.div`
  background: ${Localization.navbarBackgroundColor};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  

`;

const ButtonMenu = styles(Button)`
  &:after {
    clear: both;
    display: block;
    content: "";
    background: url(${process.env.PUBLIC_URL}/images/hamburger.svg);
    background-repeat: no-repeat;
    width: 26px;
    height: 19px;
  }
  background-color: transparent;
  z-index: 1;
  border: none;
  margin-right: 20px;
  padding: 8px;
  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: none !important;
    background: rgba(255, 255, 255, 0.3) !important;
    border: none;
    box-shadow: none !important;
    outline: 0;
  }
`;

const DropdownMenuStyle = styles(DropdownMenu)`
top:1.9% !important;
width: 230px;
left:1.5% !important;
border-radius:0px;
border:none;
box-shadow:0 0 5px #3333;
&:hover{
  background:#f2f3ff;
}`;

const DropdownStyle = styles(Dropdown)`
  font-weight: 400;
  &:hover,
  &:focus,
  &:visited {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: 0;
  }
`;
const DropdownToggleType = styles(DropdownToggle)`
  background: transparent;
  border: none;
  border-radius: 0;
  font-family: 'Roboto', sans-serif;
  padding: 4px 8px;
  height: 55px;
  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: none !important;
    background: #ffffff4d;
    border: none;
    box-shadow: none !important;
    outline: 0;
  }
`;

const DropdownItems = styles(DropdownItem)`
&:hover{
  background:#f2f3ff;
}
`;

const ModalStyle = styles(Modal)`
  .modal-content{
    background-color:#FFFFFF; 
  }
`;

const Circle = styles.div`
padding: 0 0 2px 1px;
background: #b1b6ff;
color: #ffffff;
opacity: 1;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
text-transform:uppercase;
width:32px;
height:32px;
font-size: 20px;
line-height: normal;
margin-right:10px;
  `;

export default Header;
