import React from 'react';
import { createPortal } from 'react-dom';

export default (function LoadingSpinner(props: any) {
  const { loading } = props;

  if (!loading) return null;
  return createPortal(
    <>
      <div
        style={{
          position: 'fixed',
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
          backgroundColor: '#000',
          opacity: 0.5,
          zIndex: 9999,
        }}
      />
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999,
          borderRadius: 10,
        }}
      >
        <Spinner />
      </div>
    </>,
    document.body,
  );
} as any)

function Spinner() {
  return (
    <svg width="60" height="60" viewBox="0 0 200 200" color="#B1B6FF" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="spinner-secondHalf">
          <stop offset="0%" stopOpacity="0.5" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0" stopColor="currentColor" />
        </linearGradient>
        <linearGradient id="spinner-firstHalf">
          <stop offset="0%" stopOpacity="0.5" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="1" stopColor="currentColor" />
        </linearGradient>
      </defs>

      <g strokeWidth="20">
        <path stroke="url(#spinner-secondHalf)" d="M 10 100 A 90 90 0 0 1 190 100" />
        <path stroke="url(#spinner-firstHalf)" d="M 190 100 A 90 90 0 0 1 10 100" />

        {/* <path stroke="currentColor" strokeLinecap="round" d="M 10 100 A 90 90 0 0 1 10 95" /> */}
      </g>

      <animateTransform
        from="360 0 0"
        to="0 0 0"
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1300ms"
      />
    </svg>
  );
}
