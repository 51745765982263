import * as React from "react";

const ArrowLeft = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path fill="#fff" d="m14 7.46-5 5 5 5v-10Z" />
  </svg>
);
export default ArrowLeft;
