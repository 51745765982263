/* eslint-disable @typescript-eslint/naming-convention */
import httpClient, { apiURI } from "./request";

const tokenKey = `tvsd.los.token`;
const tokenDataKey = `tvsd.los.user`;
const settingsKey = `${process.env.REACT_APP_BASENAME}.settings`;
interface LoginData {
  token: string;
  expire: string;
  refresh_token: string;
}

type CustomerLoginReq = {
  phone_number: string;
  country_code: string;
  borrower_otp: number;
};

type UserVerificationReq = {
  email: string;
  user_otp: string | number;
};

const customerLogin = (payload: CustomerLoginReq): Promise<any> => {
  const reqOpts = {
    method: "POST",
    body: JSON.stringify(payload),
  };
  return httpClient(`${apiURI}/borrower/login`, reqOpts).then(
    (resp: any): Promise<any> => {
      try {
        const data = {
          user: {
            ...resp?.borrower,
            role: "B",
            usertc: resp?.borrowertc,
            isCustomer: true,
          },
          settings: resp?.settings,
        };
        setToken(data);
        return Promise.resolve(data);
      } catch (error: any) {
        return Promise.reject(error.message);
      }
    }
  );
};

const login = (payload: any): Promise<LoginData> => {
  const reqOpts = {
    method: "POST",
    body: JSON.stringify(payload),
  };

  let url;

  if (payload?.localhost) {
    url = "dummy-login";
  } else {
    url = "verify-one-time-token";
  }

  return httpClient(url, reqOpts).then((resp: any): Promise<any> => {
    try {
      if (setToken(resp)) {
        return Promise.resolve(resp);
      } else {
        return Promise.reject("Unable to login");
      }
    } catch (error: any) {
      return Promise.reject(error);
    }
  });
};

const getToken = () => {
  if (localStorage.getItem(tokenKey)) {
    return JSON.parse(localStorage.getItem(tokenKey) || "{}");
  }
  return false;
};

const getTokenData = () => {
  if (localStorage.getItem(tokenDataKey)) {
    return JSON.parse(localStorage.getItem(tokenDataKey) || "{}");
  }
  return false;
};
const getSettingsData = () => {
  if (localStorage.getItem(settingsKey)) {
    return JSON.parse(localStorage.getItem(settingsKey) || "{}");
  }
  return false;
};
const setToken = (resp: any) => {
  if (resp && resp.user) {
    const { acc_token } = resp.user;
    localStorage.setItem(tokenDataKey, JSON.stringify(resp.user));
    localStorage.setItem(tokenKey, JSON.stringify(acc_token));
    // localStorage.setItem(settingsKey, JSON.stringify(resp.settings));
    return acc_token;
  }
  return false;
};

// called when the user navigates to a new location
const checkAuth = () => {
  const reqOpts = {
    method: "GET",
  };

  return httpClient(`${apiURI}/check-token-validity`, reqOpts).then(
    (resp: any): Promise<any> => {
      try {
        return Promise.resolve(resp);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }
  );
};

// called when the user attempts to logout
const logout = (): Promise<any> => {
  const reqOpts = {
    method: "POST",
  };
  const data = getTokenData();

  if (!getToken()) {
    Promise.resolve(removeToken());
    authProvider.redirectToLoginDomain();
  }
  const logoutEndpoint = `${apiURI}/logout`;

  return httpClient(logoutEndpoint, reqOpts).then((resp: any): Promise<any> => {
    try {
      Promise.resolve(removeToken());
      authProvider.redirectToLoginDomain();
    } catch (error: any) {
      return Promise.reject(error.message);
    }
  });
};

const removeToken = () => {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(tokenDataKey);
};

// get the current user details
const getUserDetails = () => {
  const reqOpts = {
    method: "GET",
  };

  return httpClient(`${apiURI}/users/me`, reqOpts).then(
    (resp: any): Promise<any> => {
      try {
        return Promise.resolve(resp);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }
  );
};

// reset password
const resetPassword = (payload: any): Promise<any> => {
  const reqOpts = {
    method: "POST",
    body: JSON.stringify(payload),
  };
  return httpClient(`${apiURI}/cms/user/reset-password`, reqOpts).then(
    (resp: any): Promise<any> => {
      try {
        return Promise.resolve(resp);
      } catch (error: any) {
        return Promise.reject(error.message);
      }
    }
  );
};

const redirectToLoginDomain = () => {
  if (!window.location.href.includes("localhost")) {
    window.location.href = process.env.REACT_APP_LOGIN_DOMAIN;
  }
};

const authProvider = {
  customerLogin,
  login,
  logout,
  checkAuth,
  getToken,
  getTokenData,
  removeToken,
  getUserDetails,
  getSettingsData,
  resetPassword,
  redirectToLoginDomain,
};
export default authProvider;
