import React from "react";
import ReactDOM from 'react-dom/client';
import "./index.scss";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { ConnectedRouter } from "connected-react-router";
import history from "utils/history";
import configureStore from "redux/configureStore";
import { Provider } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'tvs-unified-lib/dist/assets/index.css';

const store = configureStore({}, history);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	/* @ts-ignore */
	<Provider store={store}>
		<ConnectedRouter history={history}>
			{/* @ts-ignore */}
			<App />
		</ConnectedRouter>
	</Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
